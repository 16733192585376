<template>
    <main>
        <page-header-compact>
            Configuración de Areas
        </page-header-compact>
        <div class="container-fluid px-4">
            <div class="card mt-4">
                <div class="card-body">
                    <data-grid :data="pagination" @changePage="changePage($event)"
                        @perPageChange="perPageChange($event)" @search="search($event)">
                        <template #grid>
                            <grid-table>
                                <template #head>
                                    <grid-cell-header class="bg-light" v-for="(h, i) in headers"
                                        :key="`h_${i}`">{{ h }}</grid-cell-header>
                                </template>
                                <template #body>
                                    <grid-row v-for="(item, i) in pagination.data" :key="`d_${i}`">
                                        <grid-cell class="text-center small">
                                            {{ item.id }}
                                        </grid-cell>
                                        <grid-cell class="text-center small">
                                            {{ item.nombre }}
                                        </grid-cell>
                                        <grid-cell class="text-center small">
                                            {{ item.descripcion }}
                                        </grid-cell>
                                        <grid-cell class="text-center">
                                            <button class="btn btn-transparent btn-sm shadow-xs mx-1" type="button"
                                                @click="abrirModal(item.id)">
                                                <i class="fas fa-edit fa-fw text-success"></i> 
                                            </button>
                                            <router-link class="btn btn-light btn-xs text-primary"
                                                :to="{ name: 'examen-laboratorio.index', params: { 'id_area': item.id } }"><i class="fa fa-cog fa-fw"></i>Configurar Examenes</router-link>
                                        </grid-cell>
                                    </grid-row>
                                </template>
                            </grid-table>
                        </template>
                        <template #action-button>
                            <button class="btn btn-info btn-sm shadow-sm mb-2" type="button" data-toggle="modal"
                                data-target="#modalAreaLaboratorio">
                                Nuevo &nbsp;<i class="fas fa-plus"></i>
                            </button>
                        </template>
                    </data-grid>
                    <modalAreaLaboratorio ref="modalAreaLaboratorio" v-on:cargar-areas="getAreasLaboratorio">
                    </modalAreaLaboratorio>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import PageHeaderCompact from "../../../../../components/layouts/content/page-header-compact";
import DataGrid from "../../../../../components/common/utilities/DataGrid/DataGrid";
import GridTable from "../../../../../components/common/utilities/DataGrid/GridTable";
import GridCellHeader from "../../../../../components/common/utilities/DataGrid/GridCellHeader";
import GridRow from "../../../../../components/common/utilities/DataGrid/GridRow";
import GridCell from "../../../../../components/common/utilities/DataGrid/GridCell";

import Toast from '../../../../../components/common/utilities/toast';
import modalAreaLaboratorio from "./modalAreaLaboratorio";

import AreaLaboratorioService from '../../../../../services/AreaLaboratorioService';

export default {
    components: {
        PageHeaderCompact, GridCell, GridRow, GridCellHeader, GridTable, DataGrid, modalAreaLaboratorio
    },
    data() {
        return {
            areaslaboratorio: {},
            headers: [
                'Id', 'Nombre', 'Descripcion', 'Opción'
            ],
            pagination: {
                data: [],
                current_page: 1,
                page: 1,
                per_page: 5,
                search: '',
                total: '',
            }
        }
    },
    methods: {
        async getAreasLaboratorio() {
            try {
                this.LoaderSpinnerShow();
                const response = (await AreaLaboratorioService.list(this.pagination.search, this.pagination))?.data;
                this.areaslaboratorio = response.data;
                console.log(response.data);
                this.pagination.data = response.data;
                this.pagination.current_page = response.current_page;
                this.pagination.total = response.total;
                this.LoaderSpinnerHide();

            } catch (e) {
                console.error(e);
                Toast.fire({
                    icon: 'error',
                    title: 'Ocurrio un error al procesar la solicitud'
                });
            }
        },
        changePage(page) {
            this.pagination.page = page;
            this.getAreasLaboratorio();
        },
        perPageChange(per_page) {
            this.pagination.per_page = per_page;
            this.pagination.page = 1;
            this.getAreasLaboratorio();
        },
        search(query) {
            this.pagination.search = query;
            this.pagination.page = 1;
            this.getAreasLaboratorio();
        },
        abrirModal(id) {
            this.$refs.modalAreaLaboratorio.edit(id);
        }

    },
    async created() {
        await this.getAreasLaboratorio();
    }
}
</script>
